
import { defineComponent, ref, onMounted } from "vue";

import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

import { useForm } from "vee-validate";
import {  save_sealproform } from "@/api/sealproencode";
 

import {getcountries, getcities, getjobclassifications, getoviage}  from "@/api/common";

export default defineComponent({
  name: "new-address-modal",
    data() {
    return {   
          insured_firstName: "",
          insured_middleName: "",
          insured_lastName: "",
          insured_birthday: "",
          beneficiary_firstName:  "",
          beneficiary_middleName:  "",
          beneficiary_lastName:  "",
          beneficiary_birthday:  "",
          beneficiary_contact:  "",
          beneficiary_relationship:  "",
          coi: "",
          sealproPlan: "",
          issubmitting: false, 
          countries:  [],
          job_classification_options_list: [],
          cities: [],
          address_country: '',

          
          insured_mobileNumber: "",
          insured_email:  "",
          address_blockAndStreet: "",
          address_nameOfBuilding: "",
          address_unitNumber:  "",
          address_city:  "", 
          address_postalCode:  "",
          address_country2: "",
          job_classification: "",
          address_province:  ""

    }
  },
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: { 
       
        sealpro_encode_modal: {type: String}, 
  },  
  methods: { 
    async httpGetCountries(){ 
        this.countries = [];
        const response = await getcountries(); 
        if(response.data !== undefined && response.data.countries !== undefined) {
          this.countries = response.data.countries; 
        } 

    },
    async getJobClassifications(){  
      this.job_classification_options_list = [];
        const response = await getjobclassifications(); 
        if(response.data !== undefined && response.data.list !== undefined) {
          this.job_classification_options_list = response.data.list; 
        }
    },
    
    async getCities(country_id){   
        this.cities = [];
        const response = await getcities(country_id); 
        if(response.data !== undefined && response.data.cities !== undefined) {
            this.cities = response.data.cities; 
        }
    },
    async blurBirthdate(e){ 
        const elementValue = e.target.value;

        let params = {
          insured_birthdate : elementValue.toString()
        }

        const response = await getoviage(params); 
    },
    
    async submitRenewal(){
       this.issubmitting = true;
       let data = { 
          insured_firstName: this.insured_firstName,
          insured_middleName: this.insured_middleName,
          insured_lastName: this.insured_lastName,
          insured_birthday: this.insured_birthday,
          beneficiary_firstName:  this.beneficiary_firstName,
          beneficiary_middleName:  this.beneficiary_middleName,
          beneficiary_lastName:  this.beneficiary_lastName,
          beneficiary_birthday:  this.beneficiary_birthday,
          beneficiary_contact:  this.beneficiary_contact,
          beneficiary_relationship:  this.beneficiary_relationship, 
          address_country: this.address_country,  
          insured_mobileNumber:  this.insured_mobileNumber,
          insured_email: this.insured_email,
          address_blockAndStreet: this.address_blockAndStreet,
          address_nameOfBuilding: this.address_nameOfBuilding,
          address_unitNumber: this.address_unitNumber,
          address_city:  this.address_city,
          address_postalCode: this.address_postalCode,
          address_country2: this.address_country2,
          job_classification: this.job_classification,
          address_province:  this.address_province,
          encodetype: "AUTOCOVERAGE"


       };
       const response = await save_sealproform(data);
      if(response.data !== undefined) {
          if(response.data.result === "SUCCESS") {
                  Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary"
                  }
                }).then(() => {
                  window.location.reload();
                });
                return;
          }
            Swal.fire({
              title: "",
              text: response.data.message ,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
      }

    }
  },
  mounted() {  
    this.httpGetCountries();
    this.getJobClassifications();
  }
});
