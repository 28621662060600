
import { defineComponent, onMounted } from "vue";

import ListWidget5 from "@/components/widgets/lists/Widget5.vue"; 
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue"; 
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import AccountList from "@/components/dashboard/AccountList.vue"
import {transactionhistory}  from "@/api/transaction";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue"
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue"

import  SealProEncode from "@/views/modals/SealProEncode.vue"
import { getCurrentUser } from "@/utils/index"
import { getDashboardData } from "@/api/dashboard"
import {  hasOVISurveyResultsList } from "@/api/myprospects"
import  AdvisoryBanner from "@/components/dashboard/AdvisoryBanner.vue"

import SealProAutoCoverage from "../modals/SealProAutoCoverage.vue";

export default defineComponent({
  name: "dashboard",
  components: {
 
    ListWidget5, 
    MixedWidget2,  
    SealProEncode,
    AccountList,
    // CompanyPolicy,
    AdvisoryBanner,
    // ECShopAdvisory,
    SealProAutoCoverage
  },
  data() {
    return {
      user: {},
      dashboarddata: {},
      allowClose: true,
      inputActivationDetail: false,
      isAddAccount: false,
      componentKey: 0,
      allowChangeName: true,
      encode_now_sealpro_key: 0,
      encode_now_sealproautocoverage_key: 0,
      oviRankImage: "",
      firstCoverage: {},
      rankPrimeImage : "",
      showRankImage : true,
      loadingBalance: true,
      loading: true,
      currency : "",
      echequeUrl : "",
      echequeUrlNoError : true,
      showEChequeImage : false,
      oviadvisoryUrl : "",
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      rankPrimeImageStyle : ""
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  }, 
  methods: {
    async transactionhistory(){ 
      this.loading = true;
        const response = await transactionhistory(1);
      this.loading = false;
      },
    async hasOVISurveyResultsListOVI(){
      this.loading = true;
      const response = await hasOVISurveyResultsList();
       this.loading = false;
    },
      
    async getDashboardData() {
      this.loading = true;
      const response = await getDashboardData();
        if(response.data !== undefined ) {
          this.dashboarddata = response.data; 
          this.echequeUrl = response.data.echequeUrl;
          this.showEChequeImage = response.data.showEChequeImage;
          this.oviRankImage = "media/ovi_ranks/" + response.data.oviRank + ".png";

          if(response.data.rankPrime ==  'SEP' || response.data.rankPrime == 'GEP' || response.data.rankPrime == 'GAP'){
            this.rankPrimeImage = "media/rank_logos/" + response.data.rankPrime + "_header.png";
          } else {
            if(response.data.currentRank != "DI"){
              this.rankPrimeImage = "media/rank_logos/" + response.data.currentRank + "_header.png";
            } else {
              this.showRankImage = false;
            }
          }
          this.loading = false;
        }
        if(response.data.isOviSealProformActive) {
            let allowChangeName = false;
            if(response.data.firstCoverage !== undefined && response.data.firstCoverage !== null && response.data.firstCoverage.coverage_transtype === 'IMMEDIATE') {
              allowChangeName = true
            }
            this.firstCoverage = response.data.firstCoverage;
            this.encodeSealProNow(allowChangeName);
        }

        if(!response.data.isOviSealProformActive && response.data.isOviSealProAutoCoverageFormActive) {
            this.encodeSealProAutoCoverageNow();
        }

        this.loadingBalance = false;

        this.onResize();
    },
    addAccount() {
        this.allowClose = true; 
        this.inputActivationDetail;
        this.isAddAccount = true;
        this.allowChangeName = false;

        setTimeout(() => {   
          const btn:any = this.$refs.addAccountModalButton;
          btn.click();
        }, 500);
        
    },
    encodeSealProNow(allowChangeName) { 
        this.allowChangeName = allowChangeName;
        this.encode_now_sealpro_key += 1; //this will force reload the component
        setTimeout(() => {   
          const btn:any = this.$refs.encodeNowSealproModalButton;
          btn.click();
        }, 500);
      
    },
    encodeSealProAutoCoverageNow() {  
        this.encode_now_sealproautocoverage_key += 1; //this will force reload the component
        setTimeout(() => {   
          const btn:any = this.$refs.encodeNowSealproAutoCoverageModalButton;
          btn.click();
        }, 500);
      
    },
    showAdvisoryModal() {
        this.allowClose = true; 
        setTimeout(() => {   
          const btn:any = this.$refs.advisoryModalButton;
          btn.click();
        }, 500);
        
    },
    onResize() {
      //this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      if(this.windowWidth <= 450){
        this.oviadvisoryUrl = "https://images.empoweredconsumerism-static-files.com/ovi/advisory/ovi_better_service_update_portrait.jpg";
        this.rankPrimeImageStyle = "height: 60px; width: 60px; margin-top: 50px; margin-left: -10px; position: absolute;";
      } else {
        this.oviadvisoryUrl = "https://images.empoweredconsumerism-static-files.com/ovi/advisory/ovi_better_service_update_landscape.jpg";
        this.rankPrimeImageStyle = "height:80px;width:80px;margin-top:110px;margin-left:-160px;position:absolute;";
      }
    }
  },
  mounted(){

        this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
        });

        this.user = getCurrentUser();
        this.getDashboardData(); 
        this.hasOVISurveyResultsListOVI();

        if(localStorage.getItem("pt") === 'ecp' || localStorage.getItem("pt") === 'edp' || localStorage.getItem("pt") === 'iglow'){
          this.$router.push('/dashboardecp/reminderspage') ;
        } else if(localStorage.getItem("pt") === 'bibo' ){
            this.$router.push('/dashboardbibo') ;
        }


        
  },
  beforeUnmount(){
    console.log("Remove Event Listener onResize()");
    window.removeEventListener('resize', this.onResize);
  }
});
